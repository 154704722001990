/*eslint-disable*/
import React, { useState, useEffect } from "react"
import axios from "axios"
import { navigate } from "@reach/router"
import { Link, graphql, useStaticQuery } from "gatsby"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import InputAdornment from "@material-ui/core/InputAdornment"
import FormHelperText from "@material-ui/core/FormHelperText"
import Button from "@material-ui/core/Button"

// @material-ui/icons
import Email from "@material-ui/icons/Email"
import Lock from "@material-ui/icons/Lock"

// core components
import GridContainer from "../components/material-kit-pro/components/Grid/GridContainer"
import GridItem from "../components/material-kit-pro/components/Grid/GridItem"
import Card from "../components/material-kit-pro/components/Card/Card"
import CardBody from "../components/material-kit-pro/components/Card/CardBody"
import CardHeader from "../components/material-kit-pro/components/Card/CardHeader"
import CustomInput from "../components/material-kit-pro/components/CustomInput/CustomInput"

// own
import Footer from "../components/layout/footer"
import Header from "../components/layout/header"
import SEO from "../components/seo"
import BackgroundImageDots from "../components/BackgroundImageDots"

import loginPageStyle from "../components/material-kit-pro/styles/loginPageStyle"

const useStyles = makeStyles(loginPageStyle)

export default function LoginPage({ location }) {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
  })
  const classes = useStyles()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  // check if already logged in
  useEffect(() => {
    const token = localStorage.getItem("token")
    if (token) {
      axios
        .get(process.env.GATSBY_ADMIN_URL + "/languages", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(navigate(process.env.GATSBY_ADMIN_URL))
    }
  }, [])

  const image = useStaticQuery(graphql`
    query TwitterLoginImage {
      twitter: file(relativePath: { eq: "seo/SEOImageDefault.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
            quality: 95
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
    }
  `)

  const changeEmail = e => {
    setEmail(e.target.value)
  }

  const changePassword = e => {
    setPassword(e.target.value)
  }

  const onSubmit = e => {
    e.preventDefault()
    const navigateTo = process.env.GATSBY_ADMIN_URL

    axios
      .post(process.env.GATSBY_STRAPI_API + "/auth/local", {
        identifier: email,
        password,
      })
      .then(response => {
        localStorage.setItem("token", response.data.jwt)
        navigate(navigateTo)
        console.log(JSON.stringify(response.data))
        console.log("Going to redirect")
      })
      .catch(e => {
        if (
          e.response.data.message &&
          e.response.data.message[0] &&
          e.response.data.message[0].messages &&
          e.response.data.message[0].messages[0]
        ) {
          setErrorMessage(e.response.data.message[0].messages[0].message)
        } else {
          setErrorMessage(e.message)
        }
      })
  }

  return (
    <div>
      <Header compact />
      <BackgroundImageDots className={classes.pageHeader}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form onSubmit={onSubmit} className={classes.form}>
                  <CardHeader
                    color="primary"
                    signup
                    className={classes.cardHeader}
                  >
                    <h1
                      className={classes.cardTitle}
                      style={{ fontSize: "1.17em" }}
                    >
                      Log In
                    </h1>
                  </CardHeader>
                  <CardBody signup>
                    <CustomInput
                      id="email"
                      name="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        ariaLabel: "Email",
                        placeholder: "Email",
                        type: "email",
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        onChange: changeEmail,
                      }}
                    />
                    <CustomInput
                      id="password"
                      name="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        ariaLabel: "Password",
                        placeholder: "Password",
                        type: "password",
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                        onChange: changePassword,
                      }}
                    />
                  </CardBody>
                  <FormHelperText
                    className={classes.textCenter}
                    error={!!errorMessage}
                  >
                    {errorMessage}
                  </FormHelperText>
                  <div className={classes.textCenter}>
                    <Button
                      type="submit"
                      round
                      color="primary"
                      size="md"
                      variant="contained"
                    >
                      LOGIN
                    </Button>
                    <p>
                      <Link className={classes.link} to="/forgot-password">
                        Forgot your password?
                      </Link>
                    </p>

                    <p>
                      Not a member yet?{" "}
                      <Link className={classes.link} to="/signup">
                        Register now.
                      </Link>
                    </p>
                  </div>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </BackgroundImageDots>
      <Footer withFlex />
      <SEO
        general={{
          title: "Log In to Simpleen | Simpleen.io",
          description:
            "Already have an account? Login to get started with Simpleen and create your customized translations with DeepL.",
          path: location.pathname,
          image: image.twitter.childImageSharp.gatsbyImageData.src,
        }}
      />
    </div>
  )
}
